import { gql, useQuery } from "@apollo/client";
import React, { useState } from 'react'
import { EventButton } from '../components/events/event-sorter'
import MaterialsForm from '../components/forms/materials-form'
import { buttonmedium } from '../styles/buttons'
import { background1, Container, FlexBox, FlexBoxColumn, FlexContainer, FlexContainerRow, mq, siteheader, Wrapper } from '../styles/globalstyles'

const GET_TERMINE = gql` 
query gettermine ($id: ID!) {
    onlineEventTermin(where: {id: $id}) {
      id
      termin
      materialseite
      dokumente {
          url
          id
      }
      vorschaubild {
        id
        url
      }
    }
    abendveranstaltungTermin(where: {id: $id}) {
      id
      termin
      materialseite
      dokumente {
          description
          url
          id
      }
      vorschaubild {
        id
        url
      }
    }
    tagesseminarTermin(where: {id: $id}) {
      id
      materialseite
      dokumente {
          url
          id
      }
      vorschaubild {
        id
        url
      }
    }
  }
  `


const EventMaterialTemplate = ({pageContext}) => {
    const {data} = useQuery(GET_TERMINE, {variables: {"id": pageContext.terminId}})
    const [documents, setDocuments] = useState(false)
    const formHandler = () => {
        setDocuments(true)
    }
    let terminData = null
    if (data) {
        if (data.onlineEventTermin != null) {terminData = data.onlineEventTermin}
        if (data.abendveranstaltungTermin != null) {terminData = data.abendveranstaltungTermin}
        if (data.tagesseminarTermin != null) {terminData = data.tagesseminarTermin}
    }
    return (
        <Wrapper>
            <EventButton />
            <Container>
                <FlexContainer direction="column" justify="flex-start" align="flex-start" css={[siteheader, {paddingBottom: 0}]}>
                    <FlexBox direction="column" align="center" css={{width: "100% !important", overflow: "visible", padding: "1em"}}>
                    <h1 css={mq({textAlign: ["center", "center", "inherit"]})}>Materialien und Dokumente </h1>
                    <p css={{textAlign: "center", width: "80%"}}>Für die Veranstaltung "{pageContext.titel}"</p>
                    
                    </FlexBox>
                </FlexContainer>
                <FlexContainerRow >
                    <FlexBoxColumn css={{ gap: "1.2rem"}}>
                        <h2>
                            Ihr Zugang
                        </h2>
                        <p>
                            Füllen Sie das nebenstehende Formular aus und erhalten Sie Zugriff auf die Materialien der Veranstaltung. 
                        </p>
                        {terminData && terminData.vorschaubild && <div css={{width: "300px", height: "168px"}}><img height="85px" width="150px" src={terminData.vorschaubild.url} alt={pageContext.titel} /></div>}
                    </FlexBoxColumn>
                    <FlexBoxColumn>
                        {documents ? 
                        <div css={{width: "100%"}}>
                            {terminData && terminData.dokumente && terminData.dokumente.map((value, index) => {
                                return (
                                    <div key={index} css={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: background1, width: "100%", padding: "0.3rem 0.9rem", margin: "0.9rem 0"}}>
                                        <h5 css={{fontWeight: 600, marginBottom: 0}}>{value.description ?  value.description : `Dokument ${index + 1}`}</h5>
                                        <a href={value.url} download target="__blank" rel="noreferrer" > <button css={buttonmedium}>herunterladen</button></a>
                                    </div>
                                )
                            })

                            }
                        </div>
                        :
                        <MaterialsForm setDocuments={() => formHandler()} titel={pageContext.titel} datum={pageContext.termin} />

                        }
                    </FlexBoxColumn>

                </FlexContainerRow>

            </Container>
            
        </Wrapper>
    )
}

export default EventMaterialTemplate
