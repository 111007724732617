import React, { useState } from "react"
import { FlexBoxRow, orange} from "../../styles/globalstyles"
import { Formik, useField, Field } from "formik"
import { Input, Label, FormDiv, Select, Text, } from "../styles-form"
import * as Yup from 'yup';
import { buttonmedium } from "../../styles/buttons";
import {css} from '@emotion/react';



const Textfield = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props)
  return (
    <>
      <Label htmlFor={field.name}>
        {label}
        <Input id={field.name} {...field} {...props} error={meta.error ? false : true} />
        {meta.error && meta.touched ? (
          <p css={css`color: ${orange};`}>
            {meta.error}
          </p>
        ) : null}
        
      </Label>
    </>
  )
}
const Selector = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props)
  return (
    <>
      <Label htmlFor={field.name}>
        {label}
        <Select id={field.name} {...field} {...props} error={meta.error ? false : true} />
        {meta.error && meta.touched ? (
          <p css={css`color: ${orange};`}>
            {meta.error}
          </p>
        ) : null}
        
      </Label>
    </>
  )
}
const Checkbox = ({ label, children, ...props }) => {
  const [field, meta] = useField({ ...props, type: "checkbox" })
  return (
    <>
      <Label htmlFor={field.name}>
        <Field id={field.name}  type="checkbox" {...field} {...props} />
        <span css={css`margin-left: 0.4em;`}>{label}</span>
      
      {meta.touched && meta.error ? (
        <p css={css`color: ${orange};`}>{meta.error}</p>
      ) : null}
      </Label>
    </>
  )}
  const TextfieldLarge = ({ label, ...props }) => {
    const [field, meta, helpers] = useField(props)
    return (
      <>
        <Label htmlFor={field.name}>
          <div css={{display: "flex", flexDirection: "row", justifyContent:"space-between" }}>
          {label}
          {meta.error && meta.touched ? (
            <p css={css`color: ${orange}; margin-bottom: 0;`} > {meta.error}</p> ) : null}
          </div>
          <Text id={field.name} {...field} {...props} error={meta.error ? false : true} />
          
       </Label>
      </>
    )
  }

const SignupSchema = Yup.object().shape({
  vorname: Yup.string()
    .min(2, 'Vorname zu kurz')
    .max(50, 'Vorname zu lang')
    .required('Vorname wird benötigt'),
  nachname: Yup.string()
    .min(2, 'Nachname zu kurz')
    .max(50, 'Nachname zu lang')
    .required('Nachname wird benötigt'),
  email: Yup.string()
    .email('Ungültige E-Mail Adresse')
    .required('E-Mail Adresse wird benötigt'),
  agb: Yup.boolean().oneOf([true], 'Die AGB müssen bestätigt werden'),
  datenschutz: Yup.boolean().oneOf([true],'Die Datenschutzerklärung muss bestätigt werden'),
});

const MaterialsForm = ({setDocuments, titel, datum}) => {
    const [serverState, setServerState] = useState();
    const handleServerResponse = (ok, msg) => {
        setServerState({ok, msg});
      };

    // const handleOnSubmit = () => {
    //     setTimeout(() => {setDocuments()}, [1000])
    // }
  const handleOnSubmit = (values, actions) => {
        fetch('https://usebasin.com/f/7bc117ae416b', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
      .then(response => {
        actions.setSubmitting(false)
        actions.resetForm()
        handleServerResponse(true, "Vielen Dank für ihre Anfrage, die Dokumente erscheinen hier.")
        setTimeout(() => {setDocuments(true)}, [1000])
    }).catch(error => {
        actions.setSubmitting(false)
        handleServerResponse(false, "Da ist leider etwas schief gelaufgen")
      })
    }
    return (
        <div css={css`overflow-y: auto;width: 100%; height: 100%;    `}> 

        <Formik 
            initialValues={{veranstaltung:titel, datum: datum, vorname: "", nachname: "", firma: "", email:"", agb: false, datenschutz: false, newsletter: false  }} 
            onSubmit={handleOnSubmit}
            validationSchema={SignupSchema}
            validateOnChange={false}
            validateOnBlur={false}
            
            >
            {props => {
                    const {
                    values,
                    isSubmitting,
                    handleChange,
                    handleSubmit,
                    } = props;
                    return (
                        <form onSubmit={handleSubmit}>
                            
                            <FormDiv>
                                <FlexBoxRow css={css`width: 100%; flex-wrap: wrap;`}>
                                <div css={css`width: 100%; margin-right: 0.6em; margin-bottom: 1em`} > 
                                    <Textfield label="Vorname"  placeholder="Vorname" type="text" name="vorname" id="vorname" value={values.vorname} onChange={handleChange} />
                                </div>
                                <div css={css`width: 100%; margin-right: 0.6em; margin-bottom: 1em`}>
                                    <Textfield label="Nachname"  placeholder="Nachname" type="text" name="nachname" id="nachname" value={values.nachname} onChange={handleChange} />
                                </div>
                                <div css={css`width: 100%; margin-right: 0.6em; margin-bottom: 1em`}>
                                    <Textfield label="Firma"  placeholder="Firma" type="text" name="firma" id="firma" value={values.firma} onChange={handleChange}  />
                                </div>
                                <div css={css`width: 100%; margin-right: 0.6em; margin-bottom: 1em`}>
                                    <Textfield label="E-Mail"  placeholder="E-Mail Adresse" type="text" name="email" id="email" value={values.email} onChange={handleChange} />
                                </div>
                                <div css={css`width: 100%; margin-right: 0.6em; margin-bottom: 1em`}>
                                <Checkbox 
                                    values={values.newsletter}
                                    name="newsletter"
                                    type="checkbox"
                                    label="Ich bin einverstanden, dass Sie mich über Neuigkeiten informieren" />
                                <Checkbox 
                                    name="agb"
                                    label="Ich akzeptiere die allgemeinen Geschäftsbedinungen"
                                    values={values.agb} />
                                <Checkbox 
                                    name="datenschutz"
                                    label="Ich akzeptiere die Datenschutzbedingungen"
                                    values={values.datenschutz} />
                                {(serverState) ? <p className={!serverState.ok ? "errorMsg" : ""}>
                                    {serverState.msg}
                                    </p> : (isSubmitting ? <p>wird gesendet</p> : <button type="submit" className="buttonShadow" css={buttonmedium} >Anfrage absenden </button> )
                                    }
                                    </div>
                                </FlexBoxRow>
                            </FormDiv>
                        </form>
                    )}}
                </Formik>
                </div> 
    )
}

export default MaterialsForm
